.contact {
  color: $white;
  background-image: url("../images/Hannover-Herrenhausen.jpg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;

  .contact__header {
    font-size: 34px;
    color: $white;
  }

  .contact__details {
    a {
      color: $white;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .contact__form {
    position: relative;
    textarea {
      min-height: 146px;
    }

    .form-check-input {
      margin-left: 0;
    }

    a {
      color: $white;
      text-decoration: underline;
    }

    .loading-overlay {
      position: absolute;
      top: -10%;
      z-index: 1000;
      display: none;
      width: 100%;
      height: 120%;
      text-align: center;
      background: rgba(23, 35, 85, .8);

      .fa {
        position: absolute;
        top: 40%;
      }
    }

    &.loading {
      .loading-overlay {
        display: block;
      }
    }
  }

  .contact__icon {
    display: block;
    margin-bottom: 10px;
    font-size: 3em;
  }
}
